@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply items-center inline-block font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none dark:text-white;
  }
  .btn-primary {
    @apply btn bg-primary-400 hover:bg-primary-500 focus:ring-primary-300 dark:bg-primary-400 dark:hover:bg-primary-500 dark:focus:ring-primary-600;
  }
  .btn-secondary {
    @apply btn bg-secondary-400 hover:bg-secondary-500 focus:ring-secondary-300 dark:bg-secondary-400 dark:hover:bg-secondary-500 dark:focus:ring-secondary-600;
  }
  .btn-warning {
    @apply btn bg-warning-500 hover:bg-warning-600 focus:ring-warning-400 dark:bg-warning-500 dark:hover:bg-warning-600 dark:focus:ring-warning-700;
  }
  .btn-danger {
    @apply btn bg-danger-400 hover:bg-danger-500 focus:ring-danger-300 dark:bg-danger-400 dark:hover:bg-danger-500 dark:focus:ring-danger-600;
  }
  .btn-success {
    @apply btn bg-success-400 hover:bg-success-500 focus:ring-success-300 dark:bg-success-400 dark:hover:bg-success-500 dark:focus:ring-success-600;
  }
  .btn-disabled {
    @apply bg-gray-400 cursor-not-allowed btn hover:bg-gray-500 focus:ring-gray-300 dark:bg-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-600;
  }
  .btn-light {
    @apply text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700;
  }
  .btn-xs {
    @apply px-3 py-2 text-xs;
  }
  .btn-sm {
    @apply px-3 py-2 text-sm;
  }
  .btn-md {
    @apply px-5 text-sm py-2.5;
  }
  .btn-lg {
    @apply px-5 py-3;
  }
  .btn-xl {
    @apply px-6 py-3.5;
  }
  .btn-wide {
    @apply px-20 py-3;
  }
  .btn-full-width {
    @apply w-full py-3;
  }
  .badge {
    @apply inline-flex items-center text-xs font-medium px-2.5 py-0.5 rounded border;
  }
  .badge-primary {
    @apply badge bg-primary-100 text-primary-800 border-primary-400 dark:bg-gray-700 dark:text-primary-400 dark:border-primary-400;
  }
  .badge-secondary {
    @apply badge bg-secondary-100 text-secondary-800 border-secondary-400 dark:bg-gray-700 dark:text-secondary-400 dark:border-secondary-400;
  }
  .badge-light {
    @apply badge bg-gray-100 text-gray-600 dark:text-secondary-300 dark:bg-gray-600 border-none;
  }
  .badge-warning {
    @apply badge bg-warning-100 text-warning-800 border-warning-400 dark:bg-gray-700 dark:text-warning-300 dark:border-warning-300;
  }
  .badge-danger {
    @apply badge bg-danger-400 text-danger-800 border-danger-400 dark:bg-gray-700 dark:text-danger-400 dark:border-danger-400;
  }
  .badge-success {
    @apply badge bg-success-100 text-success-800 border-success-400 dark:bg-gray-700 dark:text-success-400 dark:border-success-400;
  }
  .badge-disabled {
    @apply badge bg-gray-100 text-gray-800 border-gray-500 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-500 cursor-not-allowed;
  }
  .text-h1 {
    @apply text-2xl font-extrabold leading-tight tracking-tight text-gray-700 md:text-4xl lg:text-5xl dark:text-gray-100;
  }
  .text-h2 {
    @apply text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-3xl lg:text-4xl dark:text-gray-100;
  }
  .text-h3 {
    @apply text-lg font-bold leading-tight tracking-tight text-gray-700 md:text-2xl lg:text-3xl dark:text-gray-100;
  }
  .text-h4 {
    @apply text-base font-bold leading-tight tracking-tight text-gray-700 md:text-xl lg:text-2xl dark:text-gray-100;
  }
  .text-h5 {
    @apply text-base font-medium leading-tight tracking-tight text-gray-700 md:text-lg lg:text-xl dark:text-gray-100;
  }
  .text-h6 {
    @apply text-sm font-medium leading-tight tracking-tight text-gray-700 md:text-base lg:text-xl dark:text-gray-100;
  }
  .alert {
    @apply text-sm border rounded-lg lg:text-base;
  }
  .alert-primary {
    @apply bg-primary-100 text-primary-800 border-primary-400 dark:border-primary-100;
  }
  .alert-danger {
    @apply bg-danger-100 text-danger-800 border-danger-400 dark:border-danger-100;
  }
  .alert-success {
    @apply bg-success-100 text-success-800 border-success-400 dark:border-success-100;
  }
  .alert-info {
    @apply bg-info-100 text-info-800 border-info-400 dark:border-info-100;
  }
  .alert-warning {
    @apply bg-warning-200 text-warning-800 border-warning-400 dark:border-warning-100;
  }
  .alert-light {
    @apply rounded-lg 0 lg:text-base;
  }
  .link {
    @apply text-primary-500 hover:text-primary-600 hover:underline dark:text-primary-200;
  }
  .text-default-color {
    @apply text-gray-600 dark:text-gray-300;
  }
  .active {
    @apply bg-gray-200 dark:bg-gray-600;
  }
  .card {
    @apply w-full px-5 py-3 mb-1 bg-white border border-gray-200 rounded-lg shadow sm:px-8 sm:py-6 dark:bg-gray-800 dark:border-gray-700;
  }
  .card-title {
    @apply mb-3 text-h4;
  }
}

/* chart styles */
.apexcharts-tooltip {
  @apply bg-white dark:bg-gray-700 text-gray-500 dark:text-gray-400 border-0 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  @apply py-2 px-4 bg-gray-100 dark:bg-gray-600 border-b border-gray-200 dark:border-gray-500 !important;
}

.apexcharts-xaxistooltip {
  @apply text-gray-500 border-0 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-text-y-value {
  @apply dark:text-white;
}

.apexcharts-xaxistooltip-text {
  @apply font-medium text-sm !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  @apply border-0 !important;
}

/* SVG map styles */
.svgMap-map-wrapper {
  @apply bg-white !important;
}

.svgMap-map-image {
  @apply dark:bg-gray-800;
}

.svgMap-map-controls-wrapper {
  @apply shadow-none left-0 bottom-0 dark:bg-gray-800 !important;
}

.svgMap-map-controls-zoom {
  @apply dark:bg-gray-800 !important;
}

.svgMap-map-wrapper .svgMap-control-button {
  @apply rounded-lg border-solid border border-gray-300 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-600 !important;
}

.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:after,
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:before {
  @apply dark:bg-gray-600 dark:hover:bg-gray-500;
}

.svgMap-map-wrapper .svgMap-control-button:first-child {
  @apply mr-2 !important;
}

.svgMap-tooltip {
  @apply bg-white dark:bg-gray-700 shadow-lg rounded-lg border-0 text-left !important;
}

.svgMap-tooltip
  .svgMap-tooltip-content-container
  .svgMap-tooltip-flag-container {
  @apply inline-block mr-2 text-left border-0 p-0 !important;
}

.svgMap-tooltip
  .svgMap-tooltip-content-container
  .svgMap-tooltip-flag-container
  .svgMap-tooltip-flag {
  @apply inline-block border-0 h-4 p-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-title {
  @apply inline-block pt-2 text-gray-900 dark:text-white font-semibold text-sm !important;
}

.svgMap-tooltip .svgMap-tooltip-content {
  @apply mt-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td {
  @apply text-sm text-left text-gray-500 dark:text-gray-400 font-normal !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td span {
  @apply text-sm text-left text-gray-900 dark:text-white font-semibold !important;
}

.svgMap-tooltip .svgMap-tooltip-pointer {
  @apply hidden !important;
}

.svgMap-map-wrapper .svgMap-country {
  @apply dark:stroke-gray-800;
}

/* kanban styles */

.drag-card {
  @apply opacity-100 !important;
  @apply rotate-6;
}

.ghost-card {
  @apply bg-gray-100/40 dark:bg-gray-600/40 !important;
}

/* calendar styles */

.fc .fc-toolbar {
  @apply flex-row-reverse justify-end px-4 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  @apply mb-5 !important;
}

.fc .fc-toolbar-title {
  @apply text-lg text-gray-900 font-semibold !important;
}

.fc .fc-today-button {
  @apply rounded-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 !important;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  @apply mx-2 !important;
}

.fc .fc-button-group .fc-prev-button,
.fc .fc-button-group .fc-next-button {
  @apply bg-white border-0 text-gray-500 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 rounded inline-flex focus:bg-gray-100 focus:ring-1 focus:ring-gray-100 justify-center !important;
}

.fc .fc-scrollgrid {
  @apply border-l-0 border-gray-200 !important;
}

.fc .fc-daygrid-day-frame {
  @apply border-gray-200 !important;
}

.fc .fc-col-header-cell-cushion {
  @apply py-3 text-base text-gray-900 font-semibold !important;
}

.fc-theme-standard th {
  @apply border-0 border-b border-gray-200 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  @apply mr-2 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  @apply ml-2 !important;
}

.fc .fc-event .fc-event-main {
  @apply p-2 bg-primary-700 hover:bg-primary-800 !important;
}

.fc .fc-h-event .fc-event-main-frame {
  @apply text-xs font-semibold !important;
}

.fc .fc-daygrid-day-frame {
  @apply hover:bg-gray-50 cursor-pointer !important;
}

/* feed styles */

@media (min-width: 1280px) {
  .feed-container {
    height: calc(100vh - 4rem);
  }
}
/*
Fix for Extra downward arrow in select widget in django cripsy forms
https://github.com/django-crispy-forms/crispy-tailwind/issues/124#issuecomment-1273641855
 */
div.relative > select + .pointer-events-none {
  display: none;
}

body {
  @apply text-gray-900 dark:text-gray-100;
}

a,
.text-primary {
  @apply dark:text-blue-400;
}

select,
textarea,
input:not([type="checkbox"]) {
  @apply text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

select.error,
textarea.error,
input:not([type="checkbox"]).error {
  @apply border-red-500;
}

input:disabled {
  @apply text-gray-600 bg-gray-400 dark:text-gray-400 dark:bg-gray-600 cursor-not-allowed;
}
input[readonly] {
  @apply text-gray-600 bg-gray-400 dark:text-gray-400 dark:bg-gray-600 cursor-not-allowed;
}

input[type="checkbox"]:disabled label,
input[type="checkbox"]:disabled
  + label[for]:not(input[type="checkbox"] + label:has(+ input)),
label[for]:has(+ input[type="checkbox"]:disabled):not(input + label) {
  @apply text-gray-600 dark:text-gray-400 cursor-not-allowed;
}

.ck.ck-editor.ck-rounded-corners
  .ck.ck-editor__top
  .ck-sticky-panel
  .ck-toolbar {
  @apply rounded-t-lg;
}
.ck.ck-editor.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  @apply rounded-b-lg;
}
.ck.ck-editor .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor .ck.ck-toolbar .ck.ck-button {
  @apply text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}
.ck.ck-editor .ck.ck-toolbar {
  @apply text-gray-900 border border-gray-300 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white;
}
.ck.ck-editor .ck.ck-toolbar .ck.ck-button.ck-on,
.ck.ck-editor .ck.ck-toolbar a.ck.ck-button.ck-on {
  @apply text-gray-900 bg-gray-200 border border-gray-300 dark:bg-gray-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white;
}

div.swal2-container div.swal2-popup {
  @apply bg-gray-50 dark:bg-gray-700 dark:text-gray-200;
}

/*  HTMX styles  */
.notification.htmx-swapping {
  opacity: 0;
  transition: opacity 1s ease-out;
}
.notification {
  transition: opacity 0.9s ease-in-out;
}
.notification.fade-out {
  opacity: 0;
}

/* custom-toast.css */
.toast-container {
  @apply flex items-center w-full max-w-sm p-4 mb-4;
}

.toast-title {
  @apply ml-3 text-base font-bold !important;
}

.toast-popup {
  background-color: #fff !important; /* light bg */
  border-radius: 0.5rem !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  color: #718096 !important; /* dark:text-gray-400 */
  --dark-bg-color: #1a202c !important;
}
.dark .toast-popup {
  background-color: #1a202c !important; /* dark bg */
  color: #cbd5e0 !important; /* dark:text-gray-400 */
}

.confirm-delete-button {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(242 106 106 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  border-radius: 0.5rem !important;
  text-align: center !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}
.confirm-delete-cancel-button {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(127 134 148 / var(--tw-bg-opacity));
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  border-radius: 0.5rem !important;
  text-align: center !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.active-tab {
  @apply bg-gray-200 text-primary-400 dark:bg-gray-800 dark:text-primary-500;
}

/* style the clear indicator ("small x") of a search input  */
input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

input[type="search"]::-ms-clear {
  cursor: pointer;
  transform: scale(1.2);
}
/* end style clear indicator */

/* Choices styles */
div.choices[data-type] .choices__inner {
  @apply block w-full text-base font-semibold text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}
div.choices[data-type] .choices__input {
  @apply mb-2 block !w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}
div.choices[data-type] .choices__list--dropdown {
  @apply absolute z-10 w-full p-3 mx-3 mt-2 bg-white border border-gray-300 rounded-lg shadow dark:border-gray-500 dark:bg-gray-600;
}
div.choices[data-type] .choices__list--dropdown.is-active:before {
  @apply mt-2;
}
div.choices[data-type] .choices__list--dropdown .choices__item {
  @apply p-2 mx-3 text-base font-semibold rounded-lg cursor-pointer dark:text-white;
}
div.choices[data-type]
  .choices__list--dropdown
  .choices__item--selectable.is-highlighted,
div.choices[data-type]
  .choices__list[aria-expanded]
  .choices__item--selectable.is-highlighted {
  @apply dark:bg-gray-400;
}
div.choices[data-type]
  .choices__list--dropdown
  .choices__item--selectable.is-selected,
div.choices[data-type]
  .choices__list[aria-expanded]
  .choices__item--selectable.is-selected {
  @apply border border-blue-400;
}

/* End Choices styles */

/* Activity Stream */

.activity .header {
  @apply mb-5;
}
.activity .activity-avatar svg {
  @apply w-10 h-10 p-2 mt-2 mr-4 bg-gray-100 rounded-full fill-blue-800 dark:fill-blue-300 dark:bg-gray-700;
}
.activity.deleted .activity-avatar svg {
  @apply fill-gray-400;
}
.activity .action span,
.activity .action a {
  @apply font-bold text-gray-800 text-lg dark:text-gray-200;
}

.activity.deleted .action span,
.activity.deleted .action a {
  @apply text-gray-500 dark:text-gray-400;
}

.activity .timestamp {
  @apply text-xs;
}

.activity .main-content {
  @apply m-4 text-gray-600 dark:text-gray-400;
}
.activity .main-content .content-preview {
  @apply font-bold;
}
.activity .main-content .supplemental-info {
  @apply text-sm;
}

.activity .footer {
  @apply mt-5 text-sm;
}

.activity .author {
  @apply m-2;
}

.activity .author img {
  @apply w-5 h-5 mx-2;
}

/* End Activity Stream */

/* Basic styles for user generated html like with ckeditor */
.editor-text,
.ck-editor__editable {
  @apply text-base text-gray-500 dark:text-gray-400;
  p {
    @apply mb-3;
  }
  strong {
    @apply font-semibold text-gray-900 dark:text-white;
  }
  u {
    @apply underline;
  }
  em {
    @apply italic;
  }
  a {
    @apply font-medium text-blue-600 underline dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700 hover:no-underline;
  }
  blockquote {
    @apply text-xl italic font-semibold text-gray-900 dark:text-white;
  }
  h1,
  h2,
  h3 {
    @apply text-h3;
  }
  h4 {
    @apply text-h4;
  }
  h5 {
    @apply text-h5;
  }
  h6 {
    @apply text-h6;
  }
  ul {
    @apply max-w-md text-gray-500 list-disc list-inside space-y-1 dark:text-gray-400;
  }
  ol {
    @apply max-w-md text-gray-500 list-decimal list-inside space-y-1 dark:text-gray-400;
  }
  hr {
    @apply h-px my-8 bg-gray-200 border-0 dark:bg-gray-700;
  }
}
/* End basic styles for user generated html like with ckeditor */

/* Hijacked navbar style */
.navbar-hijacked {
  @apply bg-red-400 dark:bg-red-900;
}
