
/* note purposes */
.INITIAL_MEETING {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:INITIAL_MEETING) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}
.FOLLOW_UP {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:FOLLOW_UP) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}
.CHANNEL_CHECK {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:CHANNEL_CHECK) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}
.COMPANY_UPDATE {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:COMPANY_UPDATE) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}
.INDUSTRY_UPDATE {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:INDUSTRY_UPDATE) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}
.RESULTS_UPDATE {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:RESULTS_UPDATE) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}
.OTHER_NOTE {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:OTHER_NOTE) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}

/* action recommendations */
.NO_ACTION {
  background-color: rgb(209 213 219);
  color: rgb(55 65 81);
}
:is(.dark .dark\:NO_ACTION) {
  background-color: rgb(55 65 81);
  color: rgb(209 213 219);
}
.START_MONITORING {
  background-color: rgb(180 198 252);
  color: rgb(66 56 157);
}
:is(.dark .dark\:START_MONITORING) {
  background-color: rgb(66 56 157);
  color: rgb(180 198 252);
}
.BUY {
  background-color: rgb(132 225 188);
  color: rgb(3 84 63);
}
:is(.dark .dark\:BUY) {
  background-color: rgb(3 84 63);
  color: rgb(132 225 188);
}
.SELL {
  background-color: rgb(248 180 180);
  color: rgb(155 28 28);
}
:is(.dark .dark\:SELL) {
  background-color: rgb(155 28 28);
  color: rgb(248 180 180);
}
