
/* note purposes */
.WL {
  @apply bg-amber-400 dark:bg-amber-500 text-amber-700 dark:text-amber-300;
}
.PF {
  @apply bg-green-300 dark:bg-green-400 text-green-600 dark:text-green-200;
}
.RM {
  @apply bg-rose-400 dark:bg-rose-500 text-rose-700 dark:text-rose-300;
}
